/* eslint-disable @typescript-eslint/no-floating-promises */
import { SavedItems, SavedItemsPageTitle } from '@components/ecommerce';
import { Layout } from '@components/layout';
import { IPage } from '@standardTypes';
import React from 'react';

interface SavedItemsProps {
  pageContext: {
    page: IPage;
  };
}

const SavedItemsPage: React.FC<SavedItemsProps> = ({ pageContext }) => {
  const { page } = pageContext;
  return (
    <Layout {...page}>
      <SavedItemsPageTitle />
      <SavedItems />
    </Layout>
  );
};
export default SavedItemsPage;
